<template>
  <canvas v-bind="canvasAttrs" dir="ltr" class="w-100" @mousemove="onMousemove" />
</template>

<script type="text/javascript">
const ratioPoint = ({ x, y }, { width, height }) => ({ x: x / width, y: y / height });

export default {
  props: { page: { type: Object, required: true } },
  data() {
    return {
      canvasWidth: undefined,
      renderTask: undefined,
    };
  },
  computed: {
    canvasAttrs() {
      let { width, height } = this.viewport;
      [width, height] = [width, height].map((dim) => Math.ceil(dim));

      return {
        width,
        height,
      };
    },
    viewport() {
      return this.page.getViewport({ scale: this.scale });
    },
    scale() {
      const { width } = this.page.getViewport({ scale: 1 });
      return (this.canvasWidth ? this.canvasWidth / width : 1) * (window.devicePixelRatio || 1);
    },
  },
  watch: {
    canvasWidth: 'renderPage',
    page: 'renderPage',
  },
  mounted() {
    this.updateCanvasWidth();
    window.addEventListener('resize', this.updateCanvasWidth);
  },
  beforeDestroy() {
    if (this.renderTask) this.renderTask.cancel();
    window.removeEventListener('resize', this.updateCanvasWidth);
  },
  methods: {
    renderPage() {
      if (this.renderTask) this.renderTask.cancel();

      const renderParams = {
        viewport: this.viewport,
        canvasContext: this.$el.getContext('2d'),
      };

      this.renderTask = this.page.render(renderParams);
      this.renderTask.promise.then(() => {
        this.renderTask = undefined;
      });
    },
    updateCanvasWidth() {
      const { width } = this.$el.getBoundingClientRect();
      this.canvasWidth = width;
    },
    onMousemove(event) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      const y = event.clientY - rect.top;
      this.$emit('cursormove', ratioPoint({ x, y }, rect));
    },
  },
};
</script>
