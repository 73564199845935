<template>
  <el-input
    v-model.number="internalValue"
    class="input-number"
    type="number"
    step="0.01"
    v-bind="$attrs"
    v-on="filteredListeners"
  />
</template>
<script>
export default {
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      internalValue: this.value,
    };
  },
  computed: {
    filteredListeners() {
      const filteredListeners = {};
      Object.keys(this.$listeners)
        .filter((key) => key !== 'input')
        .forEach((key) => (filteredListeners[key] = this.$listeners[key]));
      return filteredListeners;
    },
  },
  watch: {
    internalValue(value) {
      this.$emit('input', value);
    },
  },
};
</script>
<style scoped lang="scss">
.input-number {
  :deep(input) {
    text-align: center;
  }
  /* Chrome, Safari, Edge, Opera */
  :deep(input::-webkit-outer-spin-button),
  :deep(input::-webkit-inner-spin-button) {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  :deep(input[type='number']) {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}
</style>
