<template>
  <el-input
    v-model="internalValue"
    class="input-money"
    type="number"
    step="0.01"
    v-bind="$attrs"
    v-on="filteredListeners"
  >
    <template :slot="direction">
      <div class="currency-symbol">{{ currency }}</div>
    </template>
  </el-input>
</template>
<script type="text/javascript">
import { currency } from '@/locale/numberConfig';

export default {
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    formatter: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      internalValue: '',
    };
  },
  computed: {
    filteredListeners() {
      const filteredListeners = {};
      Object.keys(this.$listeners)
        .filter((key) => key !== 'input')
        .forEach((key) => (filteredListeners[key] = this.$listeners[key]));
      return filteredListeners;
    },
    currency() {
      if (this.formatter) {
        return this.formatter(0).replace(/\d./g, '').trim();
      }
      return (0).toLocaleString(this.$i18n.locale, currency).replace(/\d./g, '').trim();
    },
    direction() {
      return this.$i18n.direction() === 'rtl' ? 'suffix' : 'prefix';
    },
  },
  watch: {
    value: {
      handler(value) {
        if (value === undefined) {
          this.internalValue = '';
          return;
        }
        if (!this.internalValue || Math.round(this.internalValue * 100) !== value) {
          this.internalValue = (value / 100).toString();
        }
      },
      immediate: true,
    },
    internalValue(value) {
      if (!value) {
        this.$emit('input', undefined);
        return;
      }
      const moneyValue = Math.round(value * 100);
      if (moneyValue !== this.value) this.$emit('input', moneyValue);
    },
  },
};
</script>
<style scoped lang="scss">
.currency-symbol {
  height: 100%;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-money {
  :deep(input) {
    text-align: center;
  }
  /* Chrome, Safari, Edge, Opera */
  :deep(input::-webkit-outer-spin-button),
  :deep(input::-webkit-inner-spin-button) {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  :deep(input[type='number']) {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}
</style>
