<template>
  <span>
    <el-date-picker
      :value="value"
      format="MMMM yyyy"
      value-format="yyyy-MM"
      type="month"
      :clearable="clearable"
      :editable="false"
      :picker-options="pickerOptions"
      :size="size"
      v-bind="$attrs"
      @input="$emit('input', $event)"
    />
  </span>
</template>

<script>
import { useTranslate } from '@/modules/core/compositions/translate';

const getISOMonth = (date = new Date()) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  return `${year}-${month}`;
};

export default {
  props: {
    value: { type: String, default: null },
    clearable: { type: Boolean, default: false },
    size: { type: String, validator: (value) => ['mini', 'small', 'large'].includes(value), default: 'large' },
  },
  setup() {
    const $t = useTranslate();

    const shortcuts = [
      {
        text: $t('bills.currentMonth'),
        onClick: (vm) => {
          vm.$emit('pick', getISOMonth());
        },
      },
      {
        text: $t('bills.previousMonth'),
        onClick: (vm) => {
          const date = new Date();
          date.setMonth(date.getMonth() - 1);
          vm.$emit('pick', getISOMonth(date));
        },
      },
    ];

    return {
      pickerOptions: { shortcuts },
    };
  },
};
</script>
